<template>
  
  <div>
        <div>
            <template>
                <v-toolbar flat>
                    <v-btn :to="{name: 'Tools'}" 
                        class="mr-2 mt-1" 
                        color="primary"
                        plain
                    ><v-icon color="primary"  >mdi-chevron-left</v-icon><span>{{ $t('Tools') }}</span></v-btn>
                    <v-toolbar-title><v-icon class="mr-2">mdi-calculator</v-icon>{{$t("Cross Sell Calculator")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green"
                        rounded
                        @click="resetCalc()"
                        class="mr-2"
                    >
                        {{$t('Reset')}}
                    </v-btn>
                    <v-btn
                        color="primary"
                        rounded
                        @click="calculate()"
                    >
                        {{$t('Calculate')}}
                    </v-btn>
                </v-toolbar>
            </template>  
        </div>
        <div class="mt-4">            
            <div class="mt-4">
                <v-card elevation="0"> 
                    <v-card-text>
                    <v-container>
                        <v-form 
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <p class="i-text">{{$t("Year target")}}</p>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <v-text-field
                                    v-model.number="data.yearTarget"
                                    type="number"
                                    dense
                                    single-line
                                    hide-details
                                    class="shrink"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <div>{{$t("Quarter target")}}</div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <div class="n-text">{{cData.quarterTarget}}</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <p class="i-text">{{$t("Average order Size")}}</p>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <v-text-field
                                    v-model.number="data.averageOrderSize"
                                    type="number"
                                    dense
                                    single-line
                                    hide-details
                                    class="shrink"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <div>{{$t("Amount of Sales")}}</div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <div class="n-text">{{cData.amountOfSales}}</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <p class="i-text">{{$t("Closure conversion")}}</p>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <v-text-field
                                        v-model.number="data.closureConversion"
                                        type="number"
                                        dense
                                        single-line
                                        hide-details
                                        class="shrink"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <div>{{$t("Amount of cross sells")}}</div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                >
                                    <div class="n-text">{{cData.amountOfCrossSells}}</div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    </v-card-text>
                    
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                data:{
                    yearTarget:0,
                    averageOrderSize:0,
                    closureConversion:0
                },
                cData:{
                    amountOfCrossSells:0,
                    amountOfSales:0,
                    quarterTarget:0
                }
            }
        },
        methods: {
            resetCalc(){
                this.data = {
                    yearTarget:0,
                    averageOrderSize:0,
                    closureConversion:0
                }
                this.cData = {
                    amountOfCrossSells:0,
                    amountOfSales:0,
                    quarterTarget:0
                }
            },
            calculate(){

                let uri = this.$urlPrefix + '/Tools/CrossSellCalculator';
                this.axios.post(uri, this.data).then((response) => {
                    if(response.status == 200){
                        this.cData = response.data;
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
    p.i-text {
        font-weight: 600;
        padding-top: 8px;
        margin-bottom: 0px;
    }

    .n-text {
        font-size: 16px;
    } 
</style>